<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"></i18n>
<i18n locale="th" lang="yaml" >
page.title: "รายการ Drone รอปรับแก้"
page.description : "แสดงรายการ Drone รอการปรับแก้ ที่รอการยืนยันจากทาง HGR"

drone.lot.chart.name: "Lot"
drone.customer.chart.name: "เจ้าของเครื่อง"
drone.model.chart.name: "Drone"
drone.firmware.chart.name: "Firmware"
drone.status.chart.name: "สถานะเครื่อง"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header :title="$t('page.title')" :description="$t('page.description')" />
		<a-card :bordered="false">
			<DroneChangeRequestTable
				bordered
				:row-key="(record) => record.id"
				:data-source="droneChangeRequestList"
				:pagination="pagination"
				:loading="loading"
				@change="handleTableChange" />
		</a-card>
	</div>
</template>

<script>
import axios from 'axios'
import _debounce from 'lodash/debounce'

import PageMixin from '@mixins/PageMixin.vue'
import ApiError from '@utils/errors/ApiError'
import DroneChangeRequestTable from '@components/change_request/DroneChangeRequestTable.vue'

export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	components: {
		DroneChangeRequestTable,
	},
	mixins: [PageMixin],
	data() {
		return {
			searchMore: false,
			droneChangeRequestList: [],
			pagination: {
				pageSize: 20,
				current: 1,
				total: 0,
				hideOnSinglePage: true,
			},
			sort: {
				field: '',
				order: 'ascend',
			},
			loading: false,
		}
	},
	created() {
		this.debounceSearchChange = _debounce(this.handleSearchChange, 300)
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		handleSearchChange() {
			// Reset Pagination
			this.pagination.current = 1
			this.fetchData()
		},
		fetchData(toPage = null) {
			if (toPage === null) toPage = this.pagination.current
			const search = {
				page_size: this.pagination.pageSize,
				current_page: toPage,
				sort_field: this.sort.field,
				sort_order: this.sort.order,
			}
			this.loading = true
			axios
				.get('/api/change-request/search', { params: { search } })
				.then((response) => {
					const data = response.data.data
					this.droneChangeRequestList = data.droneChangeRequest
					const page = response.data.data.pagination
					this.pagination.current = toPage
					this.pagination.total = page.total
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this, error))
				})
				.finally(() => {
					this.loading = false
				})
		},
		handleTableChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.sort.field = sorter.columnKey
				this.sort.order = sorter.order
			} else {
				this.sort = {
					field: '',
					order: 'ascend',
				}
			}
			this.fetchData(pagination.current)
		},
	},
}
</script>
