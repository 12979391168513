<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"></i18n>
<i18n locale="th" lang="yaml">
change_request.field.request_date: 'วันที่ดำเนินการ'
change_request.field.change_detail: 'การปรับแก้'
change_request.field.request_comapny: 'ศูนย์บริการที่ยื่นเรื่อง'
change_request.field.request_user: 'ผู้ยื่นเรื่อง'

change_request.type.change_register: 'เปลี่ยนเจ้าของ'
change_request.type.register: 'ลงทะเบียน Drone'
change_request.type.decommission: 'ยกเลิกการใช้งาน'
change_request.type.backup: 'ปรับเป็นเครื่องสำรอง'
change_request.type.error: 'ติดต่อ Admin'

</i18n>
<template>
	<a-table
		v-bind="$attrs"
		class="mytable mytable-scroll drone-table"
		size="small"
		:pagination="pagination"
		:columns="columns"
		v-on="$listeners">
		<span slot="indexNum" slot-scope="text, record, index">{{ index + 1 + (pagination.current - 1) * pagination.pageSize }}</span>
		<span slot="droneNameRender" slot-scope="text, record">
			<my-router-link name="drone/view" :param-value="record.drone.serialNo" param-name="serial_no">{{ text }}</my-router-link>
		</span>
		<span slot="snRender" slot-scope="text, record">
			<my-router-link name="drone/view" :param-value="record.drone.serialNo" param-name="serial_no">{{ text }}</my-router-link>
		</span>
		<span slot="modelRender" slot-scope="text, record">
			<my-router-link name="drone_model/view" :param-value="record.model.id" auth-action="read" auth-module="droneModel">{{ text }}</my-router-link>
		</span>
		<span slot="lotRender" slot-scope="text, record">
			<my-router-link name="drone_lot/view" :param-value="record.lot.id">{{ text }}</my-router-link>
		</span>
		<span slot="warrantyStartDateRender" slot-scope="text">{{ text | date('LL', '-') }}</span>
		<div slot="firmwareRender" slot-scope="text" class="center">
			<span>{{ text }}</span>
		</div>
		<span slot="createdDateRender" slot-scope="text">{{ text | date('LL', '-') }}</span>
		<div slot="droneStatus" slot-scope="text" class="center">
			<DroneStatus :status="text" />
		</div>
		<div slot="changeDetail" slot-scope="text, record" class="center">
			<span>{{ changeDetailText(record.drone.status, record.droneStatus) }}</span>
		</div>

		<div slot="requestCompanyRender" slot-scope="text" class="center">
			<span>{{ getCompanyById(text).name }}</span>
		</div>
		<span slot="viewBtnRender" slot-scope="text, record">
			<my-router-link name="drone_change_request/view" :param-value="record.id" param-name="request_id">
				<a-icon type="file-search" />
			</my-router-link>
		</span>
	</a-table>
</template>

<script>
import { Table } from 'ant-design-vue'
import {mapGetters} from "vuex"
import DroneStatus from '@components/drone/DroneStatus.vue'

export default {
	components: {
		'a-table': Table,
		DroneStatus,
	},
	props: {
		pagination: {
			type: null,
			default: () => [],
		},
	},
	computed: {
		...mapGetters('user',['getCompanyById']) ,
		columns() {
			return [
				{
					title: '#',
					scopedSlots: { customRender: 'indexNum' },
				},
				{
					title: this.$t('drone.field.name'),
					dataIndex: 'drone.name',
					scopedSlots: { customRender: 'droneNameRender' },
					// sorter: true,
				},
				{
					title: this.$t('drone.field.serial_no'),
					dataIndex: 'drone.serialNo',
					scopedSlots: { customRender: 'snRender' },
					// sorter: true,
				},
				{
					title: this.$t('drone.field.drone_model_id'),
					dataIndex: 'model.name',
					scopedSlots: { customRender: 'modelRender' },
					// sorter: true,
				},
				{
					title: this.$t('drone.field.drone_lot_id'),
					dataIndex: 'lot.lotName',
					scopedSlots: { customRender: 'lotRender' },
					// sorter: true,
				},
				{
					title: this.$t('drone.field.warranty_start_date'),
					dataIndex: 'drone.warrantyStartDate',
					scopedSlots: { customRender: 'warrantyStartDateRender' },
					// sorter: true,
				},
				{
					title: this.$t('drone.field.firmware'),
					dataIndex: 'firmware.name',
					scopedSlots: { customRender: 'firmwareRender' },
					// sorter: true,
				},
				{
					title: this.$t('drone.field.status'),
					dataIndex: 'drone.status',
					scopedSlots: { customRender: 'droneStatus' },
					// sorter: true,
				},
				{
					title: this.$t('change_request.field.request_date'),
					dataIndex: 'createdTime',
					scopedSlots: { customRender: 'createdDateRender' },
					sorter: true,
				},
				{
					title: this.$t('change_request.field.change_detail'),
					scopedSlots: { customRender: 'changeDetail' },
					// sorter: true,
				},
				{
					title: this.$t('change_request.field.request_comapny'),
					dataIndex: 'requestBy.user.company',
					scopedSlots: { customRender: 'requestCompanyRender' },
					// sorter: true,
				},
				{
					title: this.$t('change_request.field.request_user'),
					dataIndex: 'requestBy.user.username',
					sorter: true,
				},
				{
					scopedSlots: { customRender: 'viewBtnRender' },
				},
			]
		},
	},
	methods: {
		changeDetailText(currentDroneStatus, newDroneStatus) {
			if (currentDroneStatus === newDroneStatus) return this.$t('change_request.type.change_register')
			switch (newDroneStatus) {
				case 'active':
					return this.$t('change_request.type.register')
				case 'decommission':
					return this.$t('change_request.type.decommission')
				case 'backup':
					return this.$t('change_request.type.backup')
				default:
					return 'ติดต่อ Admin'
			}
		},
	},
}
</script>

<style lang="less" scoped>
.drone-table::v-deep table {
	min-width: 1200px;
}
.center {
	text-align: center;
}
</style>
